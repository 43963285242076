<template>
<div>
    <h4 class="title is-4 is-marginless">{{ widget.title }}</h4>
    <div v-if="widget.intro" v-html="parsedIntro"></div>
</div>
</template>
<script>
import ReportUtils from '../utils'
export default {
    props: {
        rows: {
            type: Array,
            default: () => ([])
        },
        widget: {
            type: Object,
            default: () => ({})
        },
        date: String,
        dateKey: String,
    },

    computed: {
        parsedIntro() {
            return ReportUtils['parse_keys'](this.widget.intro, this.rows, this.date)
        }
    }
}
</script>